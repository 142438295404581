import { graphql } from 'gatsby';
import React from 'react';
import { addAuthentication } from '../../../components/addAuthentication';
import './scss/BillingInformationPage.scss';
import { DashboardPageHeading } from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => (
  <DashboardLayout>
    <div className="BillingInformation">
      <DashboardBillingPageHeading />
    </div>
  </DashboardLayout>
));

const DashboardBillingPageHeading = () => (
  <DashboardPageHeading
    heading={'My Telehealth Practice Users'}
    links={[
      { label: 'Billing History', to: '/dashboard/billing' },
      {
        label: 'Saved Payment Information',
        to: '/dashboard/billing/information',
      },
    ]}
  />
);
