import React from "react";
import { RequiresAuth } from "./misc";

/**
 * High Order Component to wrap pages that require authentication to view
 *
 * @param OriginalReactComponent
 */
export function addAuthentication(OriginalReactComponent) {
  return class extends React.Component {
    render() {
      return (


        /**
         * On edge using <AmplifyAuthenticator/>
         * Causes an ugly white screen and delay inbetween two protected pages
         * <RequiresAuth/> looks at the app's state.user object, but will also momentarily render the protected route (potential bugs)
         * Currently /dashboard routes are only protected which uses <DashboardLayout/>
         * <DashboardLayout/> includes and additonal user object check along with a splashscreen for decent UX
         */
        <RequiresAuth>
          <OriginalReactComponent/>
        </RequiresAuth>


        // <>
        //   <AmplifyAuthenticator>
        //     <AmplifySignIn
        //       slot="sign-in"
        //       hideSignUp
        //       headerText="Sign In To Amwell Now"
        //     />
        //     <OriginalReactComponent/>
        //   </AmplifyAuthenticator>
        // </>
      )
    }
  }
}
